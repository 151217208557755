.hero {
    margin: 0 auto 80px;

    @include desktop-small {
        display: flex;
    }

    &__media {
        position: relative;
        margin-bottom: 24px;

        @include desktop-small {
            flex: 0 0 66.66%;

            .is-right & {
                order: 2;
            }
        }
    }

    &__image {
        width: 100%;
    }

    &__content {
        @include desktop-small {
            flex: 0 0 33.33%;
            margin-left: 30px;

            .is-right & {
                margin-left: 0px;
                margin-right: 30px;
            }
        }
    }

    &__title {
        @include responsiveSize("font-size", 20px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 24px, 36px, 320px, 768px);
        @include PoppinsBold;
        margin-bottom: 24px;
    }

    &__text {
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 22px, 26px, 320px, 768px);
    }
}
