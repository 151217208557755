.container {
    max-width: 1280px;
    margin: 0 auto;
}

.content-wrapper {
    padding: 0 15px;
    flex: 1 1;
    min-height: 100vh;

    @include tablet {
        padding: 0 40px;
    }

    @include desktop-large {
        padding: 0 165px;
    }
}

.iframe-wrapper {
    margin: 60px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
