.text-image-block {
    margin-bottom: 70px;

    @include tablet {
        margin-bottom: 120px;
    }

    @include desktop-large {
        display: flex;
        justify-content: space-between;
    }

    &__media {
        position: relative;
        margin-bottom: 24px;

        @include desktop-large {
            flex: 0 0 730px;
            margin: 0 30px 0 0;

            &.is-right {
                order: 2;
                margin: 0 0 0 30px;
            }
        }
    }

    &__video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;

        & iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__image {
        height: 0;
        padding-bottom: 56.25%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__headline {
        @include PoppinsBold;
        @include responsiveSize("font-size", 20px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 24px, 36px, 320px, 768px);
        margin-bottom: 24px;
    }

    &__text {
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 22px, 26px, 320px, 768px);
    }
}
