.homepage-hero {
    position: relative;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto 56px;

    @include tablet {
        margin: 0 auto;
    }

    &__header {
        @include responsiveSize("margin-bottom", 95px, 115px, 300px, 1440px);
    }

    &__media {
        position: relative;
        max-width: 730px;

        @include tablet {
            margin: 0 50px;
        }

        @include desktop-small {
            margin: 0 auto;
        }

        &::before {
            display: block;
            content: "";
            width: 100%;
            padding-top: 100%;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-image: url("../../assets/images/hero-bg--mobile.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: 100%;
            transform: translate(5%, -15%);
            z-index: -1;

            @include tablet {
                background-image: url("../../assets/images/hero-bg.png");
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
        }
    }

    &__text {
        background-color: var(--primary-style-color);
        color: #fff;
        @include HeeboRegular;
        font-weight: 300;

        @include tablet {
            @include responsiveSize("max-width", 330px, 450px, 768px, 1024px);
            transform: translate(-50%, -50%);
            margin: 0 auto;
        }

        @include desktop-small {
            transform: translate(-50%, -125%);
        }
    }

    &__text-wrapper {
        padding: 24px;

        @include tablet {
            padding: 40px;
        }
    }

    &__byline {
        font-weight: 700;
    }

    &__heading {
        @include PoppinsBold;
        @include responsiveSize("font-size", 30px, 60px, 300px, 768px);
        @include responsiveSize("line-height", 36px, 72px, 300px, 768px);
    }

    &__subheading {
        @include PoppinsBold;
        @include responsiveSize("font-size", 16px, 40px, 300px, 768px);
        @include responsiveSize("line-height", 20px, 48px, 300px, 768px);
    }

    &__quote {
        @include HeeboRegular;
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 16px;
    }

    &__byline {
        font-size: 16px;
        line-height: 20px;
        @include PoppinsBold;
    }
}

.homepage-intro {
    margin: 0 auto 60px;

    @include tablet {
        margin-top: -50px;
        max-width: 1024px;
    }

    @include desktop-small {
        margin: -60px auto 120px;
    }

    &__heading {
        @include PoppinsBold;
        @include responsiveSize("font-size", 30px, 40px, 300px, 768px);
        @include responsiveSize("line-height", 36px, 48px, 300px, 768px);
        margin-bottom: 20px;
    }

    &__content {
        max-width: 640px;
        margin: 0 auto;

        @include tablet {
            margin: 0 0 0 auto;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 26px;

        @include tablet {
            font-weight: 600;
        }
    }
}
