.linklist {
    margin: 60px auto;
    max-width: 1100px;

    @include tablet {
        display: flex;
        margin: 80px auto;
    }

    @include desktop-small {
        margin: 120px auto;
    }

    &__content {
        @include tablet {
            flex: 0 0 50%;
        }
    }

    &__wrapper {
        padding-right: 20px;

        @include tablet {
            padding-right: 32px;
        }
    }

    &__heading {
        @include PoppinsBold;
        @include responsiveSize("font-size", 30px, 40px, 320px, 768px);
        @include responsiveSize("line-height", 36px, 48px, 320px, 768px);
        margin-bottom: 16px;
    }

    &__text {
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 22px, 26px, 320px, 768px);
        margin-bottom: 32px;
    }

    &__item {
        background-color: var(--secondary-style-color);
        padding: 24px;
    }

    &__list {
        @include tablet {
            flex: 0 0 50%;
        }
    }
}

.linklist-item {
    background-color: var(--secondary-style-color);
    padding: 24px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__content {
        padding-right: 10px;
    }

    &__title {
        @include PoppinsBold;
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 16px, 20px, 320px, 768px);
        margin-bottom: 4px;
    }

    &__text {
        font-size: 14px;
        line-height: 22px;
    }

    &__button {
        border-radius: 50%;
        border: 2px solid var(--primary-font-color);
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.2s;

        &:hover {
            background-color: var(--primary-font-color);

            & svg {
                fill: #fff;
            }
        }
    }
}
