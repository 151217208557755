.menu {
    display: flex;
    flex-flow: column nowrap;
    padding: 120px 20px 20px;

    @include tablet {
        max-width: 330px;
        padding: 120px 0 0 160px;
    }

    @include desktop-small {
        padding: 0;
        max-width: none;
    }

    &__item {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        position: relative;

        @include desktop-small {
            margin: 0 40px 0 0;

            &:last-child {
                margin: 0;
            }

            // this is to bridge the gap between menu item and sub menu item, to avoid it collapsing again
            // when the cursor is between the two menus
            &::before {
                content: "";
                position: absolute;
                display: block;
                height: 20px;
                bottom: -20px;
                width: 100%;
            }

            &.link-is-active {
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -75%;
                    left: 50%;
                    transform: translate(-50%, 0);
                    background-image: url("../../assets/images/underline.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 82px;
                    height: 10px;
                    animation: fadeIn 0.2s;
                }
            }

            &:hover {
                .menu__accordion {
                    max-height: none;
                    transform: translate(-50%, 0px);
                    opacity: 1;
                }
            }
        }

        &--no-sub {
            .menu__link {
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    width: 100%;
                    height: 3px;
                    background-color: var(--primary-font-color);
                    opacity: 0;
                    transition: opacity 0.2s;
                }
                &:hover {
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__link {
        text-decoration: none;
        color: #fff;
        font-size: 24px;
        line-height: 36px;
        @include PoppinsRegular;

        .link-is-active & {
            border-bottom: 3px solid #fff;
        }

        @include desktop-small {
            color: var(--primary-font-color);
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            @include PoppinsSemiBold;

            .link-is-active & {
                border-bottom: none;
            }
        }

        &--sub {
            font-size: 16px;
            line-height: 24px;
            @include HeeboRegular;

            @include desktop-small {
                display: block;
                color: #fff;
                padding: 24px 32px;
                font-size: 15px;
                line-height: 22px;
                text-transform: initial;
                @include PoppinsRegular;

                &:hover {
                    background-color: var(--primary-font-color-lighter);
                }
            }
        }
    }

    &__button {
        @include resetButton;
        border-radius: 50%;
        border: 2px solid #fff;
        width: 22px;
        height: 22px;
        position: relative;

        @include desktop-small {
            display: none;
        }

        // together, they make a +
        &::before,
        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
        }

        // |
        &::before {
            content: "";
            width: 2px;
            height: 9px;

            .is-open & {
                opacity: 0;
            }
        }

        // -
        &::after {
            content: "";
            width: 9px;
            height: 2px;
        }
    }

    &__accordion {
        overflow: hidden;
        transition: max-height 0.33s;
        flex: 1 0 100%;

        @include desktop-small {
            max-height: 0px;
            position: absolute;
            width: 100%;
            top: 40px;
            left: 50%;
            min-width: 280px;
            background-color: var(--primary-font-color);
            opacity: 0;
            transform: translate(-50%, -10px);
            transition: max-height 0s, transform 0.2s, opacity 0.2s;
        }

        .is-open & {
            max-height: 500px;
        }

        .is-closed & {
            max-height: 0px;
        }
    }

    &__sub-item {
        margin-bottom: 16px;
        padding-left: 16px;

        &:first-child {
            margin-top: 10px;
        }

        @include desktop-small {
            margin: 0;
            padding: 0;

            &:first-child {
                margin: 0;
            }
        }
    }
}

.menu-list {
    @include desktop-small {
        display: flex;
        flex-flow: row nowrap;
    }
}
