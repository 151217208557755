:root {
    --primary-font-color: hsl(223, 26%, 21%);
    --primary-font-color-lighter: hsl(223, 26%, 31%);
    --primary-style-color: hsl(215, 79%, 24%);
    --primary-style-color-darker: hsl(215, 79%, 19%);
    --primary-style-color-lighter: hsl(215, 79%, 29%);
    --secondary-style-color: hsl(41, 44%, 92%);
    --secondary-style-color-darker: hsl(41, 44%, 97%);
    --secondary-style-color-lighter: hsl(41, 44%, 87%);
    --highlight-style-color: hsl(158, 38%, 49%);
}
