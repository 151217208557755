.image-gallery {
    margin-bottom: 60px;

    @include tablet {
        margin-bottom: 90px;
    }

    @include desktop-small {
        margin-bottom: 120px;
    }

    &__title {
        @include PoppinsBold;
        @include responsiveSize("font-size", 20px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 24px, 36px, 320px, 768px);
        margin-bottom: 16px;
    }

    &__list {
        display: flex;
        flex-flow: column nowrap;

        @include tablet {
            flex-flow: row wrap;
        }
    }

    &__item {
        width: 100%;
        margin-bottom: 16px;
        @include responsiveSize("height", 193px, 360px, 320px, 767px);
        position: relative;

        & img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include tablet {
            flex: 1 0 calc(50% - 30px);
            margin: 0 30px 30px 0;
            @include responsiveSize("height", 220px, 360px, 768px, 1440px);

            &:nth-child(even) {
                margin-right: 0;
            }

            &:nth-child(odd):last-child {
                margin: 0;
                @include responsiveSize("height", 460px, 740px, 768px, 1440px);
            }
        }
    }
}
