.cms-p {
    margin-bottom: 16px;

    @include tablet {
        margin-bottom: 22px;
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }
}

.cms-link {
    color: var(--primary-font-color);
}

.cms-img {
    width: 100%;
    height: auto;
    margin: 30px auto;

    @include tablet {
        margin: 60px auto;
    }
}

.cms-h4,
.cms-h5,
.cms-h6 {
    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }
}

.cms-h4 {
    @include responsiveSize("font-size", 25px, 30px, 320px, 768px);
    @include responsiveSize("line-height", 30px, 36px, 320px, 768px);
    margin-bottom: 20px;
}

.cms-h5 {
    @include responsiveSize("font-size", 20px, 24px, 320px, 768px);
    @include responsiveSize("line-height", 24px, 30px, 320px, 768px);
    margin-bottom: 16px;
}

.cms-h6 {
    @include responsiveSize("font-size", 18px, 22px, 320px, 768px);
    @include responsiveSize("line-height", 20px, 24px, 320px, 768px);
    margin-bottom: 16px;
}
