$dotColor: var(--primary-style-color);

@keyframes dotFlashing {
    0% {
        background-color: $dotColor;
    }
    50%,
    100% {
        background-color: hsl(223, 26%, 81%);
    }
}

.loader {
    display: flex;
    justify-content: center;

    &__item {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $dotColor;
        background-color: $dotColor;
        animation: dotFlashing 1s infinite linear alternate;
        animation-delay: 0.5s;

        &::before,
        &::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: $dotColor;
            color: $dotColor;
            animation: dotFlashing 1s infinite alternate;
        }

        &::before {
            left: -15px;
            animation-delay: 0s;
        }

        &::after {
            left: 15px;
            animation-delay: 1s;
        }
    }
}
