.contact-page {
    @include desktop-small {
        display: flex;
        flex-direction: row;
    }
    &__textcontainer {
        color: rgba(40, 48, 68, 1);
        margin-bottom: 70px;

        @include tablet {
            margin-bottom: 30px;
        }
        @include desktop-small {
            margin: 0 5%;
            width: 50%;
        }

        & .cms-p {
            line-height: 26px;
        }
    }
    &__header {
        @include responsiveSize("font-size", 25px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 30px, 36px, 320px, 768px);
        @include PoppinsBold;
        margin-bottom: 10px;
    }

    &__wrapper {
        margin-bottom: 30px;
        width: 100%;

        & iframe {
            width: 100%;
        }
    }
}
