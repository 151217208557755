.headline {
    position: relative;
    text-align: center;
    margin-bottom: 50px;

    @include tablet {
        text-align: left;
        margin-bottom: 64px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        @include responsiveSize("width", 170px, 250px, 320px, 768px);
        height: 40px;
        background-color: var(--secondary-style-color);
        z-index: -1;
        transform: translateY(-33.33%);
    }

    &__text {
        @include responsiveSize("font-size", 30px, 40px, 320px, 768px);
        @include responsiveSize("line-height", 36px, 48px, 320px, 768px);
        @include PoppinsBold;
        font-weight: 700;

        @include tablet {
            padding-left: 24px;
        }
    }
}
