.errorpage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    .headline {
        &__text {
            margin-bottom: 30px;
            text-align: center;
        }
    }
    .cms-p {
        margin-bottom: 50px;
        text-align: center;
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 22px, 26px, 320px, 768px);
    }
}
