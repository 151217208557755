.columnText {
    &--grid {
        @include tablet {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-column-gap: 30px;
            padding-right: 30px;
        }
    }

    &__wrapper {
        max-width: 730px;
        margin: 0 auto 80px;

        @include tablet {
            margin: 0 auto 90px;
        }

        @include desktop-small {
            margin: 0 auto 100px;
        }
    }

    &__heading {
        @include responsiveSize("font-size", 25px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 30px, 36px, 320px, 768px);
        @include PoppinsBold;
        margin-bottom: 16px;
    }

    &__text {
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 22px, 26px, 320px, 768px);
    }
}
