.dialogue-block {
    max-width: 1110px;
    margin: 0 auto 100px;
    position: relative;

    &__wrapper {
        position: relative;
        margin-bottom: 60px;

        @include tablet {
            display: flex;
            flex-flow: column nowrap;
            margin-bottom: 16px;
        }

        @include desktop-large {
            flex-flow: row nowrap;
            margin: 0;

            &:only-child {
                margin-bottom: 192px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 100vw;
            height: calc(100% - 50px); // bg is not entirely the same height as the element itself
            transform: translateX(-50%);
            @include responsiveSizeWithCSSFunction(
                "transform",
                "translateX(-50%) translateY",
                22.5%,
                33.33%,
                300px,
                767px
            );
            background-color: var(--secondary-style-color);
            z-index: -1;

            @include tablet {
                transform: none;
                left: 0;
                width: 100%;
                height: 75%;
            }

            @include desktop-large {
                height: 100%;
                width: 66.66%;

                .is-left & {
                    right: 0;
                    left: initial;
                }
            }
        }
    }

    &__media {
        width: 100%;
        margin-bottom: 16px;

        @include tablet {
            order: 2;
            width: calc(100% - 180px); // 90px margin left and right
            margin: 0 auto;
        }

        @include desktop-large {
            width: 100%;
            max-width: 540px;
            position: absolute;
            top: 25%;
            right: 0;

            .is-left & {
                right: initial;
                left: 0;
            }
        }
    }

    &__content {
        @include tablet {
            order: 1;
            padding: 65px;
        }

        @include desktop-large {
            flex: 0 0 45%;

            .is-left & {
                flex: 0 0 42.5%;
                margin-left: auto;
            }
        }
    }

    &__image {
        max-width: 100%;
        height: auto;
    }

    &__byline {
        font-size: 16px;
        line-height: 20px;
        @include PoppinsBold;
        font-weight: 700;
        margin-bottom: 40px;
    }

    &__title {
        @include PoppinsBold;
        font-weight: 700;
        @include responsiveSize("font-size", 30px, 40px, 300px, 768px);
        @include responsiveSize("line-height", 36px, 48px, 300px, 768px);
        margin-bottom: 10px;
    }

    &__subtitle {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        margin-bottom: 40px;

        @include tablet {
            margin-bottom: 60px;
        }
    }

    &__cta {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        text-decoration: none;
        color: var(--primary-font-color);

        @include tablet {
            margin-left: 90px;
        }

        @include desktop-large {
            margin-left: 51.5%;
            margin-top: 100px;
            display: inline-block;

            .is-left & {
                margin-left: 0;
            }
        }

        &::before {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--primary-font-color);
        }
    }
}
