.dialogue-detail-hero {
    margin-bottom: 90px;

    &__media {
        position: relative;
        transform: translateY(30px);
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: var(--secondary-style-color-darker);

        @include tablet {
            transform: none;
        }

        @include desktop-small {
            padding-bottom: 50%;
        }
    }

    &__content {
        padding: 50px 0 40px;
        position: relative;

        @include tablet {
            max-width: 540px;
            margin: 0 auto;
            background-color: var(--secondary-style-color);
            padding: 30px 40px 40px;
            transform: translateY(-50px);
        }

        @include desktop-small {
            margin: 0;
            transform: translate(95px, -115px);
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100vw;
            height: 100%;
            background-color: var(--secondary-style-color);
            z-index: -1;

            @include tablet {
                content: none;
            }
        }
    }

    &__headline {
        @include responsiveSize("font-size", 25px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 30px, 36px, 320px, 768px);
        @include PoppinsBold;
        margin-bottom: 10px;
    }

    &__sub-headline {
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 21px, 26px, 320px, 768px);
        font-weight: 600;
    }

    &__label {
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 16px, 20px, 320px, 768px);
        @include PoppinsBold;
        display: block;
        margin-bottom: 24px;

        @include tablet {
            margin-bottom: 16px;
        }
    }
}
