body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;

    &.menu-is-active {
        overflow: hidden;
        @include desktop-small {
            padding-right: 16px; //compensate for missing scrollbar to avoid content suddenly jumping
        }
    }
}

.main {
    margin-top: 20px;

    @include tablet {
        margin-top: 30px;
    }

    @include desktop-small {
        margin-top: 60px;
    }
}

.page {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-family: "Heebo", "Heebo Regular", sans-serif;
    color: var(--primary-font-color);
    position: relative;
    overflow-x: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s;

        .menu-is-active & {
            opacity: 1;
            z-index: 2;
        }
    }
}
