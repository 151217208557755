.cta {
    border-top: 1px solid #dadada;

    &__wrapper {
        padding: 70px 0;

        @include tablet {
            padding: 80px 0;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-evenly;
        }
    }

    &__heading {
        @include PoppinsBold;
        @include responsiveSize("font-size", 30px, 40px, 320px, 768px);
        @include responsiveSize("line-height", 36px, 48px, 320px, 768px);
        margin-bottom: 16px;
    }

    &__text {
        line-height: 26px;
        margin-bottom: 40px;

        @include tablet {
            max-width: 475px;
            padding-right: 40px;
        }
    }
}
