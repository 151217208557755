// Small tablets (portrait view)
$screen-t-min: 768px;

// Tablets and small desktops
$screen-ds-min: 1024px;

// Large tablets and desktops
$screen-dl-min: 1440px;

// Medium devices
@mixin tablet {
    @media (min-width: #{$screen-t-min}) {
        @content;
    }
}

// Large devices
@mixin desktop-small {
    @media (min-width: #{$screen-ds-min}) {
        @content;
    }
}

// Extra large devices
@mixin desktop-large {
    @media (min-width: #{$screen-dl-min}) {
        @content;
    }
}

@mixin resetButton {
    appearance: none;
    border: none;
    background-color: transparent;
}

@mixin screenreader {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
}

.screenreader {
    @include screenreader;
}

/*
    Mixin: Fluid Type
    Magic calc + vh to allow text to be fluid between minimum
    and maximum breakpoints.

    @group typography
    @param {variable} $min-font-size [12px] - Minimum font size
    @param {variable} $max-font-size [24px] - Maximum font size
    @param {variable} $lower-range [420px] - Stop scaling font smaller at this screen resolution
    @param {variable} $upper-range [900px] - Stop scaling font larger at this screen resolution

    @example
    h1 {
        @include responsiveSize("font-size", 30px, 60px, 300px, 768px);
        @include responsiveSizeWithCSSFunction("transform", "translateX", 0px, 100px, 300px, 768px);
    }

    @site https://madebymike.com.au/writing/precise-control-responsive-typography/

    This is called a LERP, linear interpolation
    lerp(a, b, x) = a + (b -a ) * x

    The reason some values get * 0 + 1, is I think to convert the value from px to non-px, e.g. 30px to 30
    Calc cant calculate 30px + 30px, doesnt know how to work with px. It must be something like 30px + 30

    You can use this mixin for things like font-size, line-height, margins, paddings etc
    This is not animating, its calculating, therefor its impact on performance is minimal!
*/

// Default . Use for things like `font-size`, `line-height`, margins etc
@mixin responsiveSize(
    $cssProperty,
    // default values, override where needed
    $min-font-size: 16px,
    $max-font-size: 20px,
    $lower-range: 320px,
    $upper-range: 1440px
) {
    @include responsiveSizeWithCSSFunction(
        $cssProperty,
        null,
        $min-font-size,
        $max-font-size,
        $lower-range,
        $upper-range
    );
}

// Special usage for css props which have functions, like `@include responsiveSizeWithCSSFunction("transform", "translateX(-50%) translateY", 22.5%, 33.33%, 300px, 767px);`
@mixin responsiveSizeWithCSSFunction(
    $cssProperty,
    $withCSSFunction: null,
    $min-font-size: 16px,
    $max-font-size: 20px,
    $lower-range: 320px,
    $upper-range: 1440px
) {
    $lerp: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @if ($withCSSFunction != null) {
        #{$cssProperty}: #{"#{$withCSSFunction}(#{$lerp})"};
    } @else {
        #{$cssProperty}: $lerp;
    }

    @media screen and (max-width: $lower-range) {
        @if ($withCSSFunction != null) {
            #{$cssProperty}: #{"#{$withCSSFunction}(#{$min-font-size})"};
        } @else {
            #{$cssProperty}: $min-font-size;
        }
    }

    @media screen and (min-width: $upper-range) {
        @if ($withCSSFunction != null) {
            #{$cssProperty}: #{"#{$withCSSFunction}(#{$max-font-size})"};
        } @else {
            #{$cssProperty}: $max-font-size;
        }
    }
}
