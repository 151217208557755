.link-block {
    background-color: var(--secondary-style-color);
    padding: 22px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 350px;

    &__title {
        text-transform: uppercase;
        padding-right: 24px;
    }

    &__text {
        @include HeeboRegular;
        font-size: 14px;
        line-height: 22px;
        padding-right: 24px;
    }

    &__button {
        border-radius: 50%;
        border: 2px solid;
        border-color: var(--primary-font-color);
        flex: 0 0 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
