.footer {
    background-color: var(--primary-font-color);
    color: #fff;
    padding: 0 15px;

    @include tablet {
        padding: 0 40px;
    }

    @include desktop-large {
        padding: 0 165px;
    }

    &__wrapper {
        padding: 30px 0;

        @include tablet {
            display: flex;
            flex-flow: row wrap;
            padding: 80px 0px;
        }

        @include desktop-large {
            flex-flow: row nowrap;
        }
    }

    &__content {
        @include tablet {
            flex: 0 0 50%;
        }

        @include desktop-large {
            flex: 0 0 33.33%;
        }
    }

    &__headline {
        @include responsiveSize("font-size", 25px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 30px, 36px, 320px, 768px);
        font-weight: 700;
        @include PoppinsBold;
        margin-bottom: 30px;
    }

    &__text {
        margin-bottom: 48px;

        & p {
            @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
            @include responsiveSize("line-height", 22px, 26px, 320px, 768px);

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        & a {
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__links {
        margin-bottom: 48px;
        display: flex;
        flex-flow: column nowrap;

        @include tablet {
            margin-left: 120px;

            & li {
                margin-bottom: 24px;
            }
        }

        @include desktop-large {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 48px 48px 48px;
            flex: 0 0 45%;

            & li {
                margin: 0;
            }
        }
    }

    &__link {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        @include PoppinsSemiBold;
        text-transform: uppercase;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #fff;
            opacity: 0;
            transition: opacity 0.2s;
        }

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }

        @include desktop-large {
            margin: 0;

            &:not(:last-child) {
                margin: 0;
            }
        }
    }

    &__social {
        display: flex;
        flex-flow: column nowrap;

        @include tablet {
            flex: 1 0 100%;
        }

        @include desktop-large {
            flex: initial;
            margin-left: auto;
        }
    }

    &__social-item {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.2s;

        & > .icon {
            stroke: #fff;
            transition: stroke 0.2s fill 0.2s;
        }

        &:hover {
            background-color: #fff;

            & > .icon {
                fill: var(--primary-font-color);
                stroke: var(--primary-font-color);
            }
        }
    }
}
