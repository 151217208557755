.icon {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
    transition: fill 0.2s;
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-arrow-down {
    &.is-external {
        transform: rotate(-130deg);
    }
}
