.short-info-wrapper {
    margin-bottom: 80px;

    @include desktop-small {
        margin-bottom: 100px;

        & + div:not(.short-info-wrapper) {
            margin-top: 150px;
        }
    }
}

.short-info {
    @include desktop-small {
        display: flex;
        flex-flow: row nowrap;
    }

    &__wrapper {
        @include desktop-small {
            flex: 0 0 50%;
        }
    }

    &__heading {
        @include responsiveSize("font-size", 25px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 30px, 36px, 320px, 768px);
        @include PoppinsBold;
        font-weight: 700;
        margin-bottom: 54px;
        position: relative;

        @include desktop-small {
            padding-right: 100px;
            @include responsiveSize("padding-right", 100px, 125px, 1024px, 1440px);
        }

        &::before {
            content: "";
            position: absolute;
            bottom: -24px;
            left: 15px;
            @include responsiveSize("width", 274px, 250px, 320px, 768px);
            height: 8px;
            background-color: var(--highlight-style-color);
        }
    }

    &__text {
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 22px, 26px, 320px, 768px);
        padding: 0 15px;

        @include desktop-small {
            flex: 0 0 50%;
        }
    }
}
