$headings: ("h1" 60px 72px, "h2" 40px 48px, "h3" 30px 36px, "h4" 25px 30px, "h5" 20px 24px, "h6" 16px 20px);

/*
    Variable font declaration
*/
@font-face {
    font-family: "Heebo";
    src: url("../../assets/fonts/Heebo/Heebo-VariableFont_wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
}

/*
    Fallback in case variable is not supported
    Always prefer .woff2 over anything else unless you do not have any other extension
*/
@font-face {
    font-family: "Heebo Regular";
    src: local("Heebo Regular"),
        url("../../assets/fonts/Heebo/Heebo-VariableFont_wght.ttf") format("truetype-variations"),
        url("../../assets/fonts/Heebo/static/Heebo-Regular.woff2") format("woff2"),
        url("../../assets/fonts/Heebo/static/Heebo-Regular.ttf") format("ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Heebo Light";
    src: local("Heebo Light"), url("../../assets/fonts/Heebo/Heebo-VariableFont_wght.ttf") format("truetype-variations"),
        url("../../assets/fonts/Heebo/static/Heebo-Light.woff2") format("woff2"),
        url("../../assets/fonts/Heebo/static/Heebo-Light.ttf") format("ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Heebo Extrabold";
    src: local("Heebo Extrabold"),
        url("../../assets/fonts/Heebo/Heebo-VariableFont_wght.ttf") format("truetype-variations"),
        url("../../assets/fonts/Heebo/static/Heebo-ExtraBold.woff2") format("woff2"),
        url("../../assets/fonts/Heebo/static/Heebo-ExtraBold.ttf") format("ttf");
    font-weight: 600;
}

/*
    Poppins has no variable font
*/
@font-face {
    font-family: "Poppins Regular";
    src: local("Poppins Regular"), url("../../assets/fonts/Poppins/Poppins-Regular.woff2") format("woff2"),
        url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Poppins Semibold";
    src: local("Poppins Semibold"), url("../../assets/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2"),
        url("../../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Poppins Bold";
    src: local("Poppins Bold"), url("../../assets/fonts/Poppins/Poppins-Bold.woff2") format("woff2"),
        url("../../assets/fonts/Poppins/Poppins-Bold.ttf") format("ttf");
    font-weight: 700;
}

/*
    Font family mixin for easy includes and fallbacks
*/
@mixin HeeboRegular {
    font-family: "Heebo Regular", "Helvetica", "Arial", sans-serif;
    font-weight: 400;

    /*
    If this is an older browser, it will skip this and use above declaration
  */
    @supports (font-variation-settings: normal) {
        font-family: "Heebo";
    }
}

@mixin HeeboLight {
    font-family: "Heebo Light", "Helvetica", "Arial", sans-serif;
    font-weight: 300;

    @supports (font-variation-settings: normal) {
        font-family: "Heebo", "Helvetica", "Arial", sans-serif;
    }
}

@mixin HeeboExtraBold {
    font-family: "Heebo Extrabold", "Helvetica", "Arial", sans-serif;
    font-weight: 600;

    @supports (font-variation-settings: normal) {
        font-family: "Heebo", "Helvetica", "Arial", sans-serif;
    }
}

@mixin PoppinsRegular {
    font-family: "Poppins Regular", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}

@mixin PoppinsSemiBold {
    font-family: "Poppins Semibold", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}

@mixin PoppinsBold {
    font-family: "Poppins Bold", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
}

/*
    Automatic h1, h2 etc creator
*/
@each $heading, $fontSize, $lineHeight in $headings {
    .#{$heading} {
        font-size: $fontSize;
        @include PoppinsBold;
        line-height: $lineHeight;
        color: var(--primary-font-color);
    }
}

.paragraph {
    @include HeeboRegular;
    font-size: 16px;
    color: var(--primary-font-color);
    line-height: 26px;

    &--small {
        font-size: 14px;
        line-height: 22px;
    }
}

.preamble {
    @include HeeboExtraBold;
    font-size: 16px;
    line-height: 26px;
    color: var(--primary-font-color);
}

blockquote::before {
    content: open-quote;
}
blockquote::after {
    content: close-quote;
}
blockquote {
    quotes: "“" "”" "‘" "’";
}
