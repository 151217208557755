.quote {
    margin: 45px 0;
    max-width: 730px;

    @include tablet {
        margin: 60px auto;
    }

    @include desktop-small {
        margin: 120px auto;
    }

    &__text {
        @include HeeboLight;
        font-size: 22px;
        line-height: 35px;
        color: var(--primary-font-color);
        margin-bottom: 24px;
    }

    @include tablet {
        font-size: 28px;
        line-height: 42px;
    }

    &__author {
        margin-bottom: 4px;
    }

    &__byline {
        font-size: 14px;
        line-height: 22px;
    }
}
