.button {
    display: inline-flex;
    text-decoration: none;
    font-size: 16px;
    font-family: "Heebo";
    line-height: 26px;
    border-radius: 2px;
    padding: 6px 28px;
    transition: background-color 0.2s, border-color 0.2s, transform 0.15s;

    &--pri {
        color: #fff;
        border: 2px solid var(--primary-style-color);
        background-color: var(--primary-style-color);

        &:hover {
            background-color: var(--primary-style-color-darker);
            border-color: var(--primary-style-color-darker);
        }
    }

    &--sec {
        background-color: transparent;
        color: var(--primary-font-color);
        border: 2px solid #000;

        &:hover {
            background-color: var(--primary-font-color);
            border-color: var(--primary-font-color);
            color: #fff;
        }
    }

    &--big {
        padding: 10px 46px;
        font-size: 20px;
        line-height: 32px;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }

    &:active {
        transform: scale(0.975);
    }
}
