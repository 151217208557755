.cta-column {
    display: flex;
    flex-flow: column nowrap;

    @include desktop-small {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
    }
}

.cta-column-item {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 80px;
    padding: 0 15px;

    @include desktop-small {
        margin-bottom: 100px;
    }

    &__heading {
        margin-bottom: 64px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: -32px;
            left: 0;
            width: 100%;
            height: 8px;
            background-color: var(--highlight-style-color);

            @include tablet {
                max-width: 255px;
            }
        }
    }

    &__title {
        @include responsiveSize("font-size", 25px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 30px, 36px, 320px, 768px);
        @include PoppinsBold;
        margin-left: -15px;
    }

    &__text {
        margin-bottom: 40px;
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 22px, 26px, 320px, 768px);
    }
}
