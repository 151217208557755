.link-cta {
    display: inline-flex;
    @include PoppinsSemiBold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--primary-font-color);
    text-decoration: none;
    position: relative;
    transition: color 0.2s;
    margin-bottom: 16px;

    &::before {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--primary-font-color);
        transition: background-color 0.2s;
    }

    &:hover {
        &::before {
            background-color: var(--highlight-style-color);
        }
    }

    &:active {
        color: var(--primary-style-color-darker);
    }
}
