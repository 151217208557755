.person-block {
    margin-bottom: 60px;

    @include desktop-small {
        margin-bottom: 100px;
    }

    &__heading {
        @include responsiveSize("font-size", 25px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 30px, 36px, 320px, 768px);
        @include PoppinsBold;
        margin-bottom: 24px;

        @include tablet {
            margin-bottom: 32px;
        }
    }

    &__subheading {
        font-size: 16px;
        line-height: 20px;
        @include PoppinsBold;
        margin-bottom: 14px;
    }

    &__item {
        margin-bottom: 100px;

        @include desktop-small {
            display: flex;
            flex-flow: row nowrap;
            margin-bottom: 40px;
        }
    }

    &__content {
        position: relative;

        @include tablet {
            background-color: var(--secondary-style-color);
            padding: 40px;
            margin-left: 25px;
        }

        @include desktop-small {
            margin: 0;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            padding: 68px 40px;
        }
    }

    &__media {
        position: relative;
        margin-bottom: 24px;

        @include tablet {
            max-width: 450px;
            margin: 0;
            transform: translateY(70px);
        }

        @include desktop-small {
            transform: none;
            flex: 0 0 450px;
        }

        img {
            width: 100%;

            @include desktop-small {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__info {
        margin-bottom: 24px;
        position: relative;
    }

    &__content {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -68px;
            width: 100%;
            height: 100%;
            background-color: var(--secondary-style-color);
            z-index: -1;
            padding: 68px 20px 32px;
            left: 50%;
            transform: translate(-50%, 0);

            @include tablet {
                content: none;
            }
        }
    }

    &__title {
        @include responsiveSize("font-size", 20px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 24px, 36px, 320px, 768px);
        @include PoppinsBold;
        margin-bottom: 8px;
    }

    &__subtitle {
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 22px, 26px, 320px, 768px);
        @include HeeboExtraBold;
    }

    &__text {
        @include responsiveSize("font-size", 14px, 16px, 320px, 768px);
        @include responsiveSize("line-height", 22px, 26px, 320px, 768px);
    }

    &__layout {
        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;
        }

        @include desktop-small {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    &__layout-item {
        margin-bottom: 60px;
    }

    &__layout-media {
        position: relative;
        margin-bottom: 16px;

        &::before {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__layout-title {
        font-size: 16px;
        line-height: 20px;
        @include PoppinsBold;
        margin-bottom: 4px;
    }

    &__layout-subtitle {
        font-size: 14px;
        line-height: 22px;
    }
}
