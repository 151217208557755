.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;

    &__wrapper {
        display: flex;
        align-items: center;
        padding: 44px 0;
    }

    &__logo {
        width: 160px;
    }

    &__button {
        @include resetButton;
        z-index: 10;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        height: 27px;

        @include desktop-small {
            display: none;
        }
    }

    &__menu-icon {
        width: 19px;
        height: 3px;
        background-color: #000;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 0px;
        display: block;

        &:first-child {
            transform: rotate(0deg);
        }

        &:nth-child(2) {
            opacity: 1;
            transform: rotate(0deg);
        }

        &:nth-child(3) {
            transform: rotate(0deg);
        }

        .is-active & {
            background-color: #fff;

            &:first-child {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }

    &__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: var(--primary-font-color);
        transform: translateX(100%);
        opacity: 0;
        transition: opacity 0.2s;
        z-index: 3;

        &.is-active {
            transform: translateX(0);
            overflow-y: auto;
            opacity: 1;
        }

        @include desktop-small {
            opacity: 1;
            position: relative;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            transform: none;
            height: auto;
            background-color: transparent;
        }
    }
}

@include tablet {
    .header {
        &__button {
            &.is-active {
                right: 40px;
            }
        }
    }
}
