.image-list {
    margin: 0 auto 80px;

    @include desktop-small {
        margin: 0 auto 110px;
    }

    &__heading {
        @include responsiveSize("font-size", 20px, 30px, 320px, 768px);
        @include responsiveSize("line-height", 24px, 36px, 320px, 768px);
        @include PoppinsBold;
        margin-bottom: 32px;
    }

    &__container {
        display: flex;
        flex-flow: column nowrap;

        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
        }
    }

    &__item {
        background-color: var(--secondary-style-color);
        padding: 20px 40px;
        margin-bottom: 16px;
        text-decoration: none;
        color: var(--primary-font-color);
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include tablet {
            margin: 0;
            height: 100px;
        }

        @include desktop-small {
            padding: 10px 0;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__media {
        max-width: 100%;
        object-fit: contain;
        height: 100%;

        @include desktop-small {
            max-width: 50%;
        }
    }
}
